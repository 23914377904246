export const ACTIVE_POSITIONS_PNL = "Profit and Loss of your position.";
export const ACTIVE_POSITIONS_SIZE = "The notional size of your position, including leverage. This is the total market value of your position.";
export const ACTIVE_POSITIONS_ENTRY_PRICE = "The price at which you opened the position. If viewed in USD, it might vary as the base currency for most pairs is ETH.";
export const ACTIVE_POSITIONS_MARKET_PRICE = "The current price at which the assets in your position can be sold on-chain. The market price is used for the valuation and margin calculations of your position.";
export const ACTIVE_POSITIONS_LIQ_PRICE = "The price at which your position would be automatically closed to avoid bad debt.";

export const TRADE_HISTORY_ENTRY_EXIT_PRICE = "The price at which your trade was opened or closed.";
export const TRADE_HISTORY_PAID_RECEIVED = "The amount of ETH used to open a position, or the amount of ETH received upon closing your position.";
export const TRADE_HISTORY_PNL = "The profit or loss upon the closing of a position, before closing fees.";

export const OVERVIEW_PNL = "Your total realized profit or loss from closed/liquidated positions.";
export const OVERVIEW_VOLUME = "Your total volume generated through Wasabi, after leverage.";

export const OPEN_POSITION_BOOST = "While your position is open, it rewards you with Blast Points. The Boost indicates how many more Blast Points you earn compared to holding the amount of ETH used to open this position.";
export const OPEN_POSITION_ENTRY_PRICE = "The price at which your position will be opened.";
export const OPEN_POSITION_PRICE_IMPACT = "The effect your position will have on the market price of the asset in the liquidity pool due to its size after leverage.";
export const OPEN_POSITION_LIQ_PRICE = "The price at which your position would be automatically closed to avoid bad debt.";
export const OPEN_POSITION_FEES = "The cost for opening and maintaining your position to ensure the protocol functions smoothly.";
export const OPEN_POSITION_BORROW_RATE = "The current hourly interest rate, paid to liquidity providers.";
export const OPEN_POSITION_ORDER_ROUTING = "The on-chain path your order takes to open your position, designed for optimal efficiency.";

export const CLOSE_POSITION_ENTRY_PRICE = "The price at which your position was originally opened.";
export const CLOSE_POSITION_EST_EXIT_PRICE = "The price at which your position will be closed.";
export const CLOSE_POSITION_LIQ_PRICE = "The price at which your position would be automatically closed to avoid bad debt.";
export const CLOSE_POSITION_INTEREST_PAID = "The total interest accrued on your position.";
export const CLOSE_POSITION_ORDER_ROUTING = "The on-chain path your order takes to close your position, designed for optimal efficiency.";
export const CLOSE_POSITION_NET_VALUE = "The net value of your position upon closing, after deducting any fees or losses.";
export const CLOSE_POSITION_PNL = "The profit or loss that will be realized from the position.";
export const CLOSE_POSITION_FEES = "The cost to close your position, ensuring smooth protocol operation.";
export const CLOSE_POSITION_RECEIVE = "The final amount you will receive after closing your position.";

export const EARN_VAULT_TOKEN = "When depositing digital assets, you receive vault shares in exchange for tokens. Using the ERC-4626 standard, the total value of the vault is designed to grow over time. The current exchange rate reflects the total historical yield of the vault: You do not lose value when depositing.";
export const EARN_VAULT_INTEREST_RECEIVED = "The total amount of withdrawable interest received.";
export const EARN_VAULT_PENDING_INTEREST = "The sum of interest owed to your share of the vault from active trades. When trades close, this interest is received and becomes withdrawable.";
export const EARN_VAULT_TOTAL_EARNED = "The total amount of interest earned, combining both received and pending interest.";
export const EARN_VAULT_STREAK_MULTIPLIER = "Your Vault Streak Multiplier boosts the amount of Wasabi LP Points you earn from a given vault.";

export const POINTS_CURRENT_TRADING_POINTS = "Trading Points earned in the current Gold distribution window.";
export const POINTS_TOTAL_TRADING_POINTS = "Trading Points earned across all Gold distribution windows.";
export const POINTS_REFERRAL_LINK = "Earn bonus Wasabi Points from users who join using your referral link. The referral link is automatically included when you share your trades. Referral points are awarded in addition to LP and Trading Points.";